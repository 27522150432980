<template>
    <div class="container">
        <h1 class="title">
            Condiciones del Servicio
        </h1>
        <div class="content">
            <h2 class="subtitle">
                Términos y Condiciones
            </h2>
            <p>
                Bienvenido a nuestro servicio. Al utilizar nuestro sitio web,
                aceptas cumplir con los siguientes términos y
                condiciones.
            </p>
            <p>
                1. Tú eres responsable de cualquier actividad que ocurra en tu cuenta.
            </p>
            <p>
                2. No debes compartir tu contraseña ni permitir que otros accedan a tu cuenta.
            </p>
            <p>
                3. No debes utilizar el servicio para fines ilegales o no autorizados.
            </p>
            <p>
                4. Nos reservamos el derecho de suspender o cancelar
                tu cuenta si se violan los términos y condiciones.
            </p>
            <p>
                Estos son solo algunos ejemplos y no incluyen
                todos los detalles. Para obtener información completa, por
                favor revisa nuestros Términos y Condiciones detallados.
            </p>
            <p>
                Gracias por utilizar nuestro servicio.
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Conditions',
    inject: ['route'],
    computed: {
    },
};
</script>
<style>
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.title {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.subtitle {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.content {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #fff;
}
</style>
